import React, { Component } from "react";
import { Outlet, Link } from "react-router-dom";
export default function QualityControl() {
  return (
    <>
      <section className="why_us_section layout_padding">
        <div className="container">
          <div className="heading_container">
            <h2>Why Choose Us?</h2>
          </div>
          <div className="why_us_container">
            <div className="box">
              <div className="img-box">
                <img src="./Content/images/qualitycontrol2.jpeg" alt="" />
              </div>
              <div className="detail-box">
                <h5>Quality Control</h5>
                <p>
                  At textileArray doing Sourcing & Quality Control have its own
                  quality control team in Lahore, Faisalabad and Karachi. They
                  are highly skilled qualified and experince people's. Textile
                  Array have very strong quality control system due to strong
                  fellowup from Yarn, Grey Weaving to readymade products
                </p>
              </div>
            </div>
            <div className="box">
              <div className="img-box">
                <img src="./Content/images/logisticSupport.jpeg" alt="" />
              </div>
              <div className="detail-box">
                <h5>Logistic Support</h5>
                <p>
                  Our experienced logistics department works diligently and
                  communicates with the Buyers Import Department and their
                  forwarding agents for timely shipments which includes checking
                  / tracking shipping documents, freight consolidation, and
                  coordination of information between all the parties involved.
                  This cooperation results in reducing unnecessary delays and
                  demurrage charges. Textile Array issues Inspection
                  Certificates to enable supplier to negotiate documents with
                  their Bankers (if required by the Buyer and stated in the
                  payment terms / Letter of Credit).
                </p>
              </div>
            </div>
            <div className="box">
              <div className="img-box">
                <img src="./Content/images/ComplainRatio.jpeg" alt="" />
              </div>
              <div className="detail-box">
                <h5>Complaint Rate</h5>
                <p>
                  A low complaint rate often indicates high service quality and
                  customer satisfaction. As an example, Textile Array achieves a
                  complaint rate of less than 0.3%, reflecting their effective
                  inspection monitoring measures and high customer satisfaction.
                </p>
              </div>
            </div>
            <div className="box">
              <div className="img-box">
                <img src="./Content/images/brandAwareness.jpeg" alt="" />
              </div>
              <div className="detail-box">
                <h5>Brand Awareness and Popularity</h5>
                <p>
                  The reputation and brand recognition of a QC company can
                  provide valuable insights into its reliability and level of
                  customer satisfaction. Multi-national Giants, with their
                  global brand recognition, and region-specific companies like
                  Textile Array, with its solid reputation and popularity among
                  clients sourcing from Asia, is a trusted choice in the QC
                  industry. In conclusion, choosing a QC company requires
                  careful consideration of several factors. Among these options,
                  Textile Array stands out as the preferred partner for
                  businesses sourcing from Asia, offering unparalleled service
                  quality and commitment.
                </p>
              </div>
            </div>
          </div>
          <div className="btn-box">
            <Link className="nav-link" to="/qualitycontrol">
              Read More
            </Link>
          </div>
        </div>
      </section>
    </>
  );
}
