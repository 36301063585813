import React from "react";
import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <>
      <section className="info_section ">
        <div className="container">
          <div className="contact_nav">
            <Link to="/contactus">
              <i className="fa fa-phone" aria-hidden="true"></i>
              <span>Call : +923008652605</span>
            </Link>
            <Link to="/contactus">
              <i className="fa fa-phone" aria-hidden="true"></i>
              <span>Phone : +923331612006</span>
            </Link>

            <a href="mailto:info@textilearray.com">
              <i className="fa fa-envelope" aria-hidden="true"></i>
              <span>Email : info@textilearray.com</span>
            </a>
            <a href="https://maps.app.goo.gl/Rw2GqMM6i8DjorYn9" target="_blank">
              <i className="fa fa-map-marker" aria-hidden="true"></i>
              <span>Location</span>
            </a>
          </div>

          <div className="info_top ">
            <div className="row info_main_row">
              <div className="col-sm-6 col-md-4 col-lg-3">
                <div className="info_links">
                  <h4>QUICK LINKS</h4>
                  <div className="info_links_menu">
                    <Link className="" to="/home">
                      Home <span className="sr-only">(current)</span>
                    </Link>
                    <Link className="" to="/products">
                      Our Products
                    </Link>
                    <Link className="" to="/services">
                      Services
                    </Link>
                    <Link className="" to="/about">
                      {" "}
                      About
                    </Link>
                    <Link className="" to="/qualitycontrol">
                      Quality Control
                    </Link>
                    <Link className="" to="/tesimonial">
                      Testimonial
                    </Link>
                    <Link className="" to="/contactus">
                      Contact Us
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-md-4 col-lg-3 mx-auto">
                <div className="info_post">
                  <h5>LINKEDIN FEEDS</h5>
                  <div className="post_box">
                    <div className="img-box">
                      <a
                        href="https://www.linkedin.com/feed/update/urn:li:activity:7272227926308265984"
                        target="_blank"
                      >
                        <img
                          src="./Content/images/IMG-lnked-feed-1.jpg"
                          alt=""
                        />
                      </a>
                    </div>
                    <div className="img-box">
                    <a
                        href="https://www.linkedin.com/feed/update/urn:li:activity:7272515020196859906"
                        target="_blank"
                      >
                        <img
                          src="./Content/images/IMG-lnked-feed-2.jpg"
                          alt=""
                        />
                      </a>
                    </div>
                    <div className="img-box">
                    <a
                        href="https://www.linkedin.com/feed/update/urn:li:activity:7272517645046816768"
                        target="_blank"
                      >
                        <img
                          src="./Content/images/IMG-lnked-feed-3.jpg"
                          alt=""
                        />
                      </a>
                    </div>
                    <div className="img-box">
                    <a
                        href="https://www.linkedin.com/feed/update/urn:li:activity:7272526039518507008"
                        target="_blank"
                      >
                        <img
                          src="./Content/images/IMG-lnked-feed-4.jpg"
                          alt=""
                        />
                      </a>
                    </div>
                    <div className="img-box">
                    <a
                        href="https://www.linkedin.com/feed/update/urn:li:activity:7272526736926343168"
                        target="_blank"
                      >
                        <img
                          src="./Content/images/IMG-lnked-feed-5.jpg"
                          alt=""
                        />
                      </a>
                    </div>
                    <div className="img-box">
                    <a
                        href="https://www.linkedin.com/feed/update/urn:li:activity:7272527084982292481"
                        target="_blank"
                      >
                        <img
                          src="./Content/images/IMG-lnked-feed-6.jpg"
                          alt=""
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="info_bottom">
            <div className="row">
              <div className="col-md-2">
                <div className="info_logo">
                  <Link to="/home">
                    <img
                      src="./Content/images/textile-arrayLongLogo.jpg"
                      alt=""
                    />
                  </Link>
                </div>
              </div>
              <div className="col-md-4 ml-auto">
                <div className="social_box">
                  <a
                    href="https://www.facebook.com/profile.php?id=61570572410052&mibextid=ZbWKwL"
                    target="_blank"
                  >
                    <i className="fa fa-facebook" aria-hidden="true"></i>
                  </a>
                  <a href="">
                    <i className="fa fa-twitter" aria-hidden="true"></i>
                  </a>
                  <a
                    href="https://www.linkedin.com/company/textile-array"
                    target="_blank"
                  >
                    <i className="fa fa-linkedin" aria-hidden="true"></i>
                  </a>
                  <a href="">
                    <i className="fa fa-instagram" aria-hidden="true"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
