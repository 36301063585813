import React from 'react';
import Navbar from './Components/Navbar';
import About from './Components/About';
import Services from './Components/Services';
import QualityControl from './Components/QualityControl';
import Testimonial from './Components/Testimonial';
import ContactUs from './Components/ContactUs';
import Footer from './Components/Footer';
import ReactDOM from "react-dom/client";
import Home from './Components/Home';
import Products from './Components/Products';
import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";

function App() {
  return (
    <>
     <BrowserRouter>
     <Navbar />
              <Routes>                
                <Route  path="/" element={<Home/>}/>
                <Route  path="/home" element={<Home/>}/>
                <Route path="/about" element= {<About />}/>
                <Route path="/products" element= {<Products />}/>
                <Route path="/services" element= {<Services />}/>
                <Route path="/qualitycontrol" element= {<QualityControl />}/>
                <Route path="/testimonial" element= {<Testimonial />}/>
                <Route path="/contactus" element= {<ContactUs />}/>          
              </Routes>
              <Footer/>  
      </BrowserRouter>
    
    </>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);
 export default App;
