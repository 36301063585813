import React from "react";
import { Link } from "react-router-dom";
export default function Services() {
  return (
    <>
      <section className="service_section layout_padding">
        <div className="service_container">
          <div className="container ">
            <div className="heading_container">
              <h2>Services</h2>
              <p>
                Arranging Buyers’ visits and accompanying them to suppliers’
                factories while in Pakistan. Alternately, Fran International
                coordinates with the Buyers/suppliers for meetings at Buyer’s
                Head Office or in convenient locations such as Lahore, Karachi,
                Faisalabad, Dubai, Dhaka etc. Sourcing the best products at the
                most competitive prices as per the Buyers requirements.
                Correspondence with Principal and suppliers to ensure smooth
                communications. Development and Order Sampling. Follow-up of
                progress and for timely deliveries of the orders. Informing the
                Buyers about the market trends in Pakistan. Exclusively
                computerized system of purchase and follow up of materials,
                Order and Production Follow up, Production Audits analyzed to
                ensure accuracy and avoid eleventh hour surprises. Professional
                Services.
              </p>
            </div>
            <div className="row">
              <div className="col-md-6 col-lg-3">
                <div className="box ">
                  <div className="img-box">
                    <img src="./Content/images/qualitycontrol.jpeg" alt="" />
                  </div>
                  <div className="detail-box">
                    <h5>Quality Control Services</h5>
                    <p>
                      we have a deep understanding of the challenges faced by
                      overseas customers when it comes to quality control in
                      Asia. Our team will work closely with you to tailor our
                      services to meet your specific needs and requirements.
                      From initial material inspections to final inspections, we
                      will provide detailed reports and recommendations to help
                      you ensure that your products meet the highest standards
                      of quality and compliance.
                    </p>
                    <Link className="nav-link" to="/services">
                      Read More
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-3">
                <div className="box ">
                  <div className="img-box">
                    <img src="./Content/images/SupplierEvaluationServices.jpeg" alt="" />
                  </div>
                  <div className="detail-box">
                    <h5>Supplier Evaluation Services</h5>
                    <p>
                      we provide a comprehensive range of supplier evaluation
                      services to help you establish and maintain strong
                      partnerships with reliable suppliers. Our evaluations
                      cover various aspects of supplier performance, social
                      responsibility, and financial stability.
                    </p>
                    <Link className="nav-link" to="/services">
                      Read More
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-3">
                <div className="box ">
                  <div className="img-box">
                    <img src="./Content/images/Cargo-Inspections.jpeg" alt="" />
                  </div>
                  <div className="detail-box">
                    <h5>Pre-Shipment Inspection</h5>
                    <p>
                      Pre-Shipment Inspection (PSI), also known as Final Random
                      Inspection, is the most common inspection type to confirm
                      the whole shipment’s quality level. It normally requires
                      the production to be completed and at least 80% of goods
                      packed into cartons. The inspected samples are selected at
                      random, according to international standards and
                      procedures. Textile Array uses ANSI/ASQ Z1.4-2008 (AQL) as
                      its sampling standard and acceptable level of quality.
                    </p>
                    <Link className="nav-link" to="/services">
                      Read More
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-3">
                <div className="box ">
                  <div className="img-box">
                    <img src="./Content/images/checklist-daily-report-data-desk.jpeg" alt="" />
                  </div>
                  <div className="detail-box">
                    <h5>Obtain Textile Array Sample Report</h5>
                    <p>
                      we believe in having detailed, easy to understand reports.
                      In most cases this is the only information/evidence the
                      customer will receive about the quality of the goods
                      before the good actually arrive. It is crucial that this
                      report is accurate, timely, comprehensive and can be
                      understood at a glance.
                    </p>
                    <Link className="nav-link" to="/services">
                      Read More
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
