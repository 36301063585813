import React from "react";
import { Link } from "react-router-dom";
export default function Products() {
  return (
    <>
      <section className="product_section layout_padding">
        <div className="product_container">
          <div className="container ">
            <div className="heading_container">
            <h2>Our Products</h2>
              <h5>
                TextileArray specialize in offering a diverse selection of
                high-quality fabrics, including 100% Cotton, Polyester/Cotton
                blends, CVC (Chief Value Cotton), Cotton/Lycra, and a variety of
                other premium blends that combine cotton and polyester for
                enhanced durability and comfort.
              </h5>
            </div>
            <div className="row">
              <div className="col-md-12 col-lg-12">
                <ul>
                  <li>Yarn</li>
                  <li>Fabric</li>
                  <li>Work Wear Fabric</li>
                  <li>Fashion Bedding</li>
                  <li>Towel</li>
                  <li>Kitchen & Table Linen</li>
                  <li>Hospitality</li>
                  <li>Windows & Curtains</li>
                  <li>Health Cares, (Fabric + Gown)</li>
                  <li>Knitted and Woven Garments</li>
                  <li>Apparels</li>
                  <li>Socks</li>
                  <li>Denim</li>
                </ul>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 col-lg-3">
                <div className="box ">
                  <div className="img-box">
                    <img src="images/s2.png" alt="" />
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-3">
                <div className="box ">
                  <div className="img-box">
                    <img src="images/s3.png" alt="" />
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-3">
                <div className="box ">
                  <div className="img-box">
                    <img src="images/s1.png" alt="" />
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-3">
                <div className="box ">
                  <div className="img-box">
                    <img src="images/s4.png" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
